import React, { FC } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import * as s from "./NotFoundContainer.module.scss"
import CollectionsContainer from "@components/homePage/CollectionsContainer"
import { Collection } from "@utils/globalTypes"

type Props = {
  collections: Collection[]
}

const NotFoundContainer: FC<Props> = ({ collections }) => {
  const { notFoundImage } = useStaticQuery(query)
  return (
    <div className={s.notFoundContainer}>
      <GatsbyImage
        image={notFoundImage.childImageSharp.gatsbyImageData}
        alt="confirm not found container"
        className={s.notFoundImage}
      />
      <h2 className={s.text}>
        <span className={s.largeText}>Oops!</span>
        {"  "}
        We can't seem to find the page you're looking for! Browse Other Page?
      </h2>
      <CollectionsContainer collections={collections} />
    </div>
  )
}

export default NotFoundContainer

const query = graphql`
  query notFound {
    notFoundImage: file(relativePath: { eq: "not-found.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`
