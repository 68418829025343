import React, { FC } from "react"
import NotFoundContainer from "@components/notFoundPage/NotFoundContainer"
import { AllGraphQLResponse, Collection } from "@utils/globalTypes"
import SEO from "@components/common/SEO"
import { graphql } from "gatsby"

type Props = {
  data: {
    allShopifyCollection: AllGraphQLResponse<Collection>
  }
  location: Location
}

const NotFoundPage: FC<Props> = ({
  data: {
    allShopifyCollection: { edges },
  },
  location,
}) => {
  const metadata = {
    location: location.href,
  }
  const collections = edges.map((edge) => edge.node)

  return (
    <>
      <SEO {...metadata} />
      <NotFoundContainer collections={collections} />
    </>
  )
}
export default NotFoundPage

export const pageQuery = graphql`
  query {
    allShopifyCollection(
      filter: {
        handle: {
          in: ["retail-brands", "covid-19-testing", "drug-of-abuse-testing"]
        }
      }
    ) {
      edges {
        node {
          id
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          handle
        }
      }
    }
  }
`
